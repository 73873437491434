@import "src/assets/variables";
.logo {
  img {
    height: 2rem;
    padding: .5rem;
  }
  @media (min-width: $medium-size) {
    img {
      height: 4rem;
      padding: 1rem;
    }

  }
}