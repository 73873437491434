@import "src/assets/variables";

.section-amenities {
  color: $primary-background-color;
  .heading{
    .emphasized {
      color: $primary-background-color;
    }
  }

  background: url("./Amenities-background-mobile.png") center bottom/cover;
  @media (min-width: $medium-size) {
    background: url("./Amenities-background.png") center bottom/cover;
    ul {
      column-count: 1;
    }
    .section-amenities--content {
      display: flex;
      padding-left: 3rem;
    }
  }
}
