@import "src/assets/variables";

.section-tour {
  text-align: center;
  padding: 1rem;
  background: $primary-color;
  color: $primary-background-color;

  h1 {
    font-weight: 400;
  }

  @media (min-width: $medium-size) {
    .tour-content {
      display: flex;
      align-items: center;
      justify-content: space-around;

    }
  }
}

