@import url('https://fonts.googleapis.com/css?family=Arvo:400,600,700|Raleway:400,600,700&display=swap');
@import "~@renaissancerentals/renaissance-component/lib/assets/App";

.header-info {
  background: $secondary-background-color;
  text-align: center;

  .nav-info-header {

    padding: 0.01rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;

    h5 {
      font-family: $heading-font;
      font-weight: 300;
      color: $primary-color;

      a {
        font-weight: 600;
        color: $primary-color;
      }
    }
  }
}

main {
  .section-feature {
    text-align: center;
  }

  .heading {
    border: none;

    .emphasized {
      background: none;
      text-transform: uppercase;

    }
  }

  .sort-filters {
    .muncher-button:hover {
      color: white;
    }
  }

  .section-property-location {
    h3 {
      font-size: 1.3rem;
    }

    p {
      font-size: .8rem;

      i {
        font-family: $main-font;
        font-size: .8rem;
      }
    }

    a {
      color: $primary-color;
      cursor: pointer;
      text-decoration: underline;
    }

    //.property--aside {
    //  color: $section-heading-color;
    //}
  }

  .section-floorplan {
    a {
      color: $primary-color;
    }

    .muncher-button--primary {
      background: $secondary-color;
    }
  }

}
