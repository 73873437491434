@import "src/assets/variables";

.section__hero {
  text-align: center;
  background: url("./Hero-background-mobile.png") right bottom/cover;
  padding: 0;
  color: $primary-background-color;

  img {
    width: 100vw;
  }

  .hero--content {
    padding: 1rem;

    .together {
      white-space: nowrap;
    }

    h1 {
      font-weight: 400;
    }

    .btn {
      font-size: .9rem;
    }
  }
}

@media (min-width: $medium-size) {
  .section__hero {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url("./Hero-background.png") right bottom/cover;

    img {
      width: 70vw;
      object-fit: cover;
    }

    .hero--content {
      width: 25vw;
      max-width: 25rem;
      padding-left: 1rem;
      padding-right: 1rem;

      .btn {
        white-space: normal;
      }

    }

  }

}

