$primary-color: #65817b !default;
$secondary-color: #4d5054 !default;
$primary-background-color: #ffffff !default;
$secondary-background-color: #ebebe5 !default;
$tertiary-background-color: $primary-color !default;
$tertiary-color: #e17a4c !default;
$link-color: $tertiary-color !default;
$link-hover-color: $primary-color !default;

$section-heading-color: $primary-color !default;
$main-font: 'Arvo', serif !default;
$heading-font: 'Raleway', sans-serif !default;
$nav-font: $main-font !default;
$italic-font: $main-font !default;
$button-font: $main-font !default;

$button-color: $primary-color !default;
$slider-thumb-color: $primary-color !default;

$floorplan-background-color: #f7f7f7 !default;
@import "~@renaissancerentals/renaissance-component/lib/assets/variables";
